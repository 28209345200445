import React, { useState, useEffect } from "react";
import styled from "styled-components";
import tw from "twin.macro";
// import { css } from "styled-components/macro"; //eslint-disable-line

import ReactModalAdapter from "../../helpers/ReactModalAdapter.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";

// import logo from "../../images/logo-sjain.svg";
import { ReactComponent as PlayIcon } from "feather-icons/dist/icons/phone.svg";
import { ReactComponent as CloseIcon } from "feather-icons/dist/icons/x.svg";

import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'

import "./sjain.css";

const Actions = tw.div`fixed right-[1rem] bottom-0 md:right-[4rem] md:bottom-[2rem] z-10`;
const PopupButton = styled.button`
  ${tw`bg-second-500 rounded-full p-3 md:p-5 transition duration-300 animate-bounce focus:outline-none`}
  .playIcon {
    ${tw`stroke-2 w-6 h-6 md:w-10 md:h-10 text-white hocus:text-white`}
  }
`;

const StyledModal = styled(ReactModalAdapter)`
  &.mainHeroModal__overlay {
    ${tw`fixed inset-0 flex justify-center items-center z-50 bg-gray-700 bg-opacity-75 backdrop-blur-sm`}
  }
  &.mainHeroModal__content {
    ${tw`xl:mx-auto m-0 sm:m-16 w-full xl:w-1/2 2xl:w-1/3 absolute inset-x-0 flex justify-center items-center rounded-lg bg-white outline-none`}
  }
  .content {
    ${tw`w-full flex flex-col justify-center items-center p-8 lg:p-16`};
    
    img {
        ${tw`w-32`}
    }
    h2 {
        ${tw`my-5 px-3 md:p-0 text-3xl text-gray-800 font-bold text-center`}
    }
    p {
        ${tw`text-xl text-gray-700 font-medium text-center`}
    }
   .phoneInp {
        ${tw`col-span-2 w-full mt-6 border-2 p-3 focus:outline-none text-gray-600 font-medium transition duration-300 hocus:border-primary-500`}
    }
    .phoneInp input {
        ${tw`focus:outline-none`}
    }
    .alert-danger {
        ${tw`bg-red-500 text-white col-span-2 px-2 py-1`}
    }
    
    .alert-success {
        ${tw`bg-green-500 text-white col-span-2 px-2 py-1`}
    }
  }
`;
const CloseModalButton = tw.button`absolute top-0 right-0 mt-2 mr-2 lg:mt-8 lg:mr-8 hocus:text-primary-500`;


const DivHeader = tw.div`w-full flex items-center justify-evenly mb-4`;
const DivHeaderCall = tw.div`bg-gray-300 px-5 py-3 font-bold cursor-pointer`;
const DivHeaderMeeting = tw.div`bg-gray-300 px-5 py-3 font-bold cursor-pointer`;



const Form = tw.form`w-full mt-4 md:mt-6 text-sm grid grid-cols-2 gap-2 max-w-lg mx-auto md:mx-0`
const Input = tw.input`w-full mt-6 border-2 p-3 focus:outline-none text-gray-600 font-medium transition duration-300 hocus:border-primary-500`
const Select = tw.select`mt-6 first:mt-0 border-2 p-3 focus:outline-none text-gray-600 font-medium transition duration-300 hocus:border-primary-500`
const SubmitButton = tw(PrimaryButtonBase)`inline-block mt-4 col-span-2`

export default ({
    // submitButtonText = "Call Me!",
    formAction = "#",
    formMethod = "POST",
}) => {
    const [modalIsOpen, setModalIsOpen] = useState(false);

    const toggleModal = () => {
        setModalIsOpen(!modalIsOpen);
        setcallType(localStorage.getItem('callType'));
        setFormData({ ...FormData, callType: localStorage.getItem('callType') });
    };

    const [callType, setcallType] = useState(localStorage.getItem('callType'));
    const [MobileValue, setMobileValue] = useState();
    const [isAction, setisAction] = useState(false);
    const [msgClass, setmsgClass] = useState('alert-danger');
    const [message, setMessage] = useState('');
    const [FormData, setFormData] = useState({
        callType,
        companySize: '',
        email: '',
        mobile: MobileValue,
        name: '',
        website: '',
    });
    const HandleFormData = (event) => {
        setFormData({ ...FormData, [event.target.name]: event.target.value });
        console.log(FormData);
    };
    const BASEAPIURL = "https://landing.sjain.io/api/";
    const handleSubmit = async (e) => {
        e.preventDefault();
        setisAction(true);
        setmsgClass('alert-warning');
        setMessage('Processing');
        try {
            const res = await fetch(`${BASEAPIURL}docall.php?v=0.02`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(FormData),
            });
            const resJson = await res.json();
            setisAction(false);
            if (res.status === 200) {
                setmsgClass('alert-danger');
                if (resJson.status === 1) {
                    gtagReportConversion();
                    setMobileValue('');
                    setmsgClass('alert-success');
                    setFormData({
                        callType,
                        companySize: '',
                        email: '',
                        mobile: MobileValue,
                        name: '',
                        website: '',
                    });
                }
                setMessage(resJson.message);
            } else {
                setMessage(resJson.message);
            }
        } catch (err) {
            setisAction(false);
            console.log(err);
        }
    };

    function gtagReportConversion() {
        window.gtag_report_conversion();
    }
    useEffect(() => {
        setcallType(localStorage.getItem('callType'));
    }, []);
    return (
        <>
            <Actions>
                <PopupButton id="openSjainPopup" onClick={toggleModal}>
                    <PlayIcon className="playIcon" />
                </PopupButton>
            </Actions>
            <StyledModal
                closeTimeoutMS={300}
                className="mainHeroModal"
                isOpen={modalIsOpen}
                onRequestClose={toggleModal}
                shouldCloseOnOverlayClick={true}
            >
                <CloseModalButton onClick={toggleModal}>
                    <CloseIcon tw="w-6 h-6" />
                </CloseModalButton>
                <div className="content">
                    {/* <img src={logo} alt="Sjain Ventures" /> */}

                    <DivHeader>
                        <DivHeaderCall
                            style={{ color: callType === 'callme' ? '#dc2626' : '' }}
                            onClick={(e) => {
                                localStorage.setItem('callType', 'callme');
                                setcallType('callme');
                                setFormData({ ...FormData, callType: 'meeting' });
                            }}
                        >
                            Call Me
                        </DivHeaderCall>
                        <DivHeaderMeeting
                            style={{ color: callType === 'meeting' ? '#dc2626' : '' }}
                            onClick={(e) => {
                                localStorage.setItem('callType', 'meeting');
                                setcallType('meeting');
                                setFormData({ ...FormData, callType: 'meeting' });
                            }}
                        >
                            Schedule a Meeting
                        </DivHeaderMeeting>
                    </DivHeader>



                    <p>Would you like to receive callback in 28 seconds?</p>
                    <Form action={formAction} method={formMethod} onSubmit={handleSubmit}>
                        {
                            callType === 'meeting' ? (
                                <>
                                    <Input
                                        type="text"
                                        required
                                        value={FormData.name}
                                        name="name"
                                        placeholder="Your Name"
                                        onChange={(e) => {
                                            HandleFormData(e);
                                        }}
                                    />

                                    <Input
                                        type="email"
                                        required
                                        name="email"
                                        value={FormData.email}
                                        placeholder="Email Address"
                                        onChange={(e) => {
                                            HandleFormData(e);
                                        }}
                                    />

                                    <PhoneInput
                                        required
                                        placeholder="Enter phone number"
                                        value={MobileValue}
                                        onChange={setMobileValue}
                                        className="phoneInp"
                                    />

                                    <Input
                                        type="url"
                                        name="website"
                                        value={FormData.website}
                                        placeholder="Your Website"
                                        onChange={(e) => {
                                            HandleFormData(e);
                                        }}
                                    />

                                    <Select
                                        value={FormData.companySize}
                                        name="companySize"
                                        defaultValue={'company-size'}
                                        onChange={(e) => {
                                            HandleFormData(e);
                                        }}
                                    >
                                        <option value="" disabled>Company Size</option>
                                        <option value="1-10 Employees">1-10 Employees</option>
                                        <option value="11-50 Employees">11-50 Employees</option>
                                        <option value="51-100 Employees">51-100 Employees</option>
                                        <option value="101-250 Employees">101-250 Employees</option>
                                        <option value="250-1k Employees">250-1k Employees</option>
                                        <option value="Over 1k Employees">Over 1k Employees</option>
                                    </Select>

                                </>
                            ) : (
                                <>
                                    <Input
                                        type="text"
                                        value={FormData.name}
                                        required
                                        name="name"
                                        placeholder="Your Name"
                                        onChange={(e) => {
                                            HandleFormData(e);
                                        }}
                                    />

                                    <Input
                                        type="email"
                                        value={FormData.email}
                                        required
                                        name="email"
                                        placeholder="Email Address"
                                        onChange={(e) => {
                                            HandleFormData(e);
                                        }}
                                    />

                                    <PhoneInput
                                        required
                                        placeholder="Enter phone number"
                                        value={MobileValue}
                                        onChange={setMobileValue}
                                        className="phoneInp"
                                    />
                                </>
                            )
                        }

                        <div
                            className={`${msgClass} alert message hide_empty_div mb-3`}
                        >
                            {message ? <span>{message}</span> : ''}
                        </div>
                        <SubmitButton
                            style={{ display: isAction ? 'none' : '' }}
                            type="submit"
                            onClick={(e) => {
                                setFormData({ ...FormData, mobile: MobileValue });
                            }}
                        >
                            {callType === 'meeting' ? 'Schedule your demo' : 'Call me'}
                        </SubmitButton>
                    </Form>
                </div>
            </StyledModal>
        </>
    );
};
