import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import HomeImg2 from "images/new-home-img2.svg";
import { ReactComponent as HomeShapeMain } from "../../images/home-shape-main.svg";

const Container = tw.div`relative`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto py-20 md:py-24 items-center`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(Column)`md:w-6/12 flex-shrink-0 relative`;
const TextColumn = styled(Column)(props => [
    tw`md:w-6/12 mt-16 md:mt-0`,
    props.textOnLeft ? tw`md:mr-12 lg:mr-16 md:order-first` : tw`md:ml-12 lg:ml-16 md:order-last`
]);

const HomeShape = styled(HomeShapeMain)`
    ${tw`pointer-events-none opacity-25 absolute left-0 bottom-0 h-56 w-56 transform -translate-x-24 translate-y-16 -z-10 hidden md:block`}
`;

const Image = styled.img(props => []);

const TextContent = tw.div`text-center md:text-left`;

const Subheading = tw(SubheadingBase)`text-center md:text-left`;
const Heading = tw(
    SectionHeading
)`mt-4 font-black text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`;
const Description = tw.p`mt-8 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`;

export default ({
    subheading = "Quality Work",
    heading = (
        <>
            Designed & Developed by <span tw="text-second-500">Professionals</span>
        </>
    ),
    description = "Experience the expertise of our professional team in designing and developing exceptional mobile and web applications. With meticulous attention to detail and a passion for innovation, we create user-centric solutions that stand out and drive business success. Trust our professionals to bring your ideas to life.",
    imageSrc = HomeImg2,
    textOnLeft = true
}) => {
    // The textOnLeft boolean prop can be used to display either the text on left or right side of the image.

    return (
        <Container>
            <TwoColumn>
                <ImageColumn>
                    <Image src={imageSrc} />
                </ImageColumn>
                <TextColumn textOnLeft={textOnLeft}>
                    <TextContent>
                        <Subheading>{subheading}</Subheading>
                        <Heading>{heading}</Heading>
                        <Description>{description}</Description>
                    </TextContent>
                </TextColumn>
            </TwoColumn>
            <HomeShape />
        </Container>
    );
};
