import "slick-carousel/slick/slick.css";
import React, { useState } from "react";
import Slider from "react-slick";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts.js";
import HomeImg5 from "images/new-home-img5.svg";
import { ReactComponent as StarIconBase } from "images/star-icon.svg";
import { ReactComponent as ArrowLeftIcon } from "images/arrow-left-3-icon.svg";
import { ReactComponent as ArrowRightIcon } from "images/arrow-right-3-icon.svg";
import { ReactComponent as HomeShapeMain } from "../../images/home-shape-main.svg";

const Row = tw.div`flex flex-col md:flex-row justify-between items-center`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(Column)`md:w-5/12 xl:w-6/12 flex-shrink-0 relative`;
const TextColumn = styled(Column)(props => [
    tw`md:w-7/12 xl:w-6/12 mt-16 md:mt-0`,
    props.textOnLeft ? tw`md:pr-12 lg:pr-16 md:order-first` : tw`md:pl-12 lg:pl-16 md:order-last`
]);

const HomeShape = styled(HomeShapeMain)`
    ${tw`pointer-events-none opacity-25 absolute right-0 bottom-0 h-56 w-56 transform translate-x-24 translate-y-16 -z-10 hidden md:block`}
`;

const Image = styled.img(props => []);

const Subheading = tw(SubheadingBase)`text-center md:text-left`;
const Heading = tw(
    SectionHeading
)`mt-4 font-black text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`;

const TestimonialSlider = styled(Slider)`
  ${tw`w-full mt-8 text-center md:text-left`}
  .slick-track {
    ${tw`flex`}
  }
  .slick-slide {
    ${tw`h-auto flex justify-center mb-1`}
  }
`;

const Testimonial = tw.div`outline-none h-full flex! flex-col`;
const StarsContainer = styled.div``;
const StarIcon = tw(StarIconBase)`inline-block w-5 h-5 text-orange-400 fill-current mr-1 last:mr-0`;
const TestimonialHeading = tw.div`mt-4 text-xl font-bold`;
const Quote = tw.blockquote`mt-4 mb-8 sm:mb-10 leading-relaxed font-medium text-gray-700`;

const CustomerInfoAndControlsContainer = tw.div`mt-auto flex justify-between items-center flex-col sm:flex-row`;

const CustomerInfo = tw.div`flex flex-col sm:flex-row items-center justify-center lg:justify-start`;
const CustomerProfilePicture = tw.img`rounded-full w-16 h-16 sm:w-20 sm:h-20`;
const CustomerTextInfo = tw.div`text-center md:text-left sm:ml-6 mt-2 sm:mt-0`;
const CustomerName = tw.h5`font-bold text-xl`;
const CustomerTitle = tw.p`font-medium text-secondary-100`;

const Controls = styled.div`
  ${tw`flex mt-8 sm:mt-0`}
  .divider {
    ${tw`my-3 border-r`}
  }
`;
const ControlButton = styled.button`
  ${tw`mx-3 p-4 rounded-full transition duration-300 bg-gray-200 hover:bg-gray-300 text-primary-500 hover:text-primary-700 focus:outline-none focus:shadow-outline`}
  svg {
    ${tw`w-4 h-4 stroke-3`}
  }
`;

export default ({
    imageSrc = HomeImg5,
    subheading = "Testimonials",
    heading = (
        <>
            Our Clients <span tw="text-second-500">Love</span> Us
        </>
    ),
    textOnLeft = false,
    testimonials = [
        {
            stars: 5,
            profileImageSrc: "https://tamilnaducouncil.ac.in/wp-content/uploads/2020/04/dummy-avatar.jpg",
            heading: "Amazing User Experience",
            quote: "We heard from one of our business partner that Sjain Ventures is the best web & app development company, so we thought to give them a try. We're impressed with how well they integrated into the internal team's ways of working. Much appreciation.",
            customerName: "Pranjal Sahu",
            customerTitle: ""
        },
        {
            stars: 5,
            profileImageSrc: "https://tamilnaducouncil.ac.in/wp-content/uploads/2020/04/dummy-avatar.jpg",
            heading: "Love the Developer Experience & Design Principles!",
            quote: "It was our first project with Sjain Ventures. They gathered all our requirements and delivered us the exact project in no time. Looking forward to work more with them. Gladly recommend them to businesses looking for the best IT development agency.",
            customerName: "Ravi Verma",
            customerTitle: ""
        },
        {
            stars: 5,
            profileImageSrc: "https://tamilnaducouncil.ac.in/wp-content/uploads/2020/04/dummy-avatar.jpg",
            heading: "Highly Recommended",
            quote: "We decided to work with Sjain Ventures on our project because they have been building customize software to fix unique problems. They came highly recommended by all their clients, and they have a track record of fully understanding the client's requirements and delivering exactly what is needed.",
            customerName: "Hemant Kumar",
            customerTitle: ""
        },
        {
            stars: 5,
            profileImageSrc: "https://tamilnaducouncil.ac.in/wp-content/uploads/2020/04/dummy-avatar.jpg",
            heading: "Cost-Eeffective Solution",
            quote: "Sjain Ventures's experience in developing robust software is a valuable asset in support of our app development requirements. We have worked closely with them over the past five months to come to a cost-effective solution that suits our requirements.",
            customerName: "Mayank Hirkane",
            customerTitle: ""
        },
        {
            stars: 5,
            profileImageSrc: "https://tamilnaducouncil.ac.in/wp-content/uploads/2020/04/dummy-avatar.jpg",
            heading: "Deliver the Best Solution",
            quote: "For all our IT development needs, we are working with Sjain Ventures for a long time. What we really like about Sjain is their broad range of app development services and they always deliver the best solution in the industry.",
            customerName: "Uphaar Gifts & Mobile",
            customerTitle: "Owner"
        },
        {
            stars: 5,
            profileImageSrc: "https://tamilnaducouncil.ac.in/wp-content/uploads/2020/04/dummy-avatar.jpg",
            heading: "Infinite Improvements",
            quote: "We've seen infinite improvements in our online business with Sjain Ventures. Sjain helps us drive customers to our website through their effective digital marketing and IT development service.",
            customerName: "Anjali Agarwal",
            customerTitle: ""
        }
    ]
}) => {
    const [sliderRef, setSliderRef] = useState(null);

    return (
        <Container>
            <ContentWithPaddingXl>
                <Row>
                    <ImageColumn>
                        <Image src={imageSrc} />
                    </ImageColumn>
                    <TextColumn textOnLeft={textOnLeft}>
                        <Subheading>{subheading}</Subheading>
                        <Heading>{heading}</Heading>
                        <TestimonialSlider arrows={false} ref={setSliderRef}>
                            {testimonials.map((testimonial, index) => (
                                <Testimonial key={index}>
                                    <StarsContainer>
                                        {Array.from({ length: testimonial.stars }).map((_, indexIcon) => (
                                            <StarIcon key={indexIcon} />
                                        ))}
                                    </StarsContainer>
                                    <TestimonialHeading>{testimonial.heading}</TestimonialHeading>
                                    <Quote>{testimonial.quote}</Quote>
                                    <CustomerInfoAndControlsContainer>
                                        <CustomerInfo>
                                            <CustomerProfilePicture src={testimonial.profileImageSrc} alt={testimonial.customerName} />
                                            <CustomerTextInfo>
                                                <CustomerName>{testimonial.customerName}</CustomerName>
                                                <CustomerTitle>{testimonial.customerTitle}</CustomerTitle>
                                            </CustomerTextInfo>
                                        </CustomerInfo>
                                        <Controls>
                                            <ControlButton onClick={sliderRef?.slickPrev}>
                                                <ArrowLeftIcon />
                                            </ControlButton>
                                            <div className="divider" />
                                            <ControlButton onClick={sliderRef?.slickNext}>
                                                <ArrowRightIcon />
                                            </ControlButton>
                                        </Controls>
                                    </CustomerInfoAndControlsContainer>
                                </Testimonial>
                            ))}
                        </TestimonialSlider>
                    </TextColumn>
                </Row>
            </ContentWithPaddingXl>
            <HomeShape />
        </Container>
    );
};
