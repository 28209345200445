import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading } from "components/misc/Headings.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import HomeImg1 from "images/new-home-img1.svg";
import { ReactComponent as HomeShapeMain } from "../../images/home-shape-main.svg";

const Container = tw.div`relative`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto py-20 md:py-24`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(Column)`md:w-6/12 flex-shrink-0 h-80 md:h-auto`;
const TextColumn = styled(Column)(props => [
    tw`md:w-6/12 mt-16 md:mt-0`,
    props.textOnLeft ? tw`md:mr-12 lg:mr-16 md:order-first` : tw`md:ml-12 lg:ml-16 md:order-last`
]);

const HomeShape = styled(HomeShapeMain)`
    ${tw`pointer-events-none opacity-25 absolute left-0 bottom-0 h-56 w-56 transform -translate-x-24 translate-y-16 -z-10 hidden md:block`}
`;

const Image = styled.div(props => [
    `background-image: url("${props.imageSrc}");`,
    tw`rounded bg-contain bg-no-repeat bg-center h-full`,
]);
const TextContent = tw.div`text-center md:text-left`;

const Heading = tw(SectionHeading)`font-black text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`;
const Description = tw.p`mt-8 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`;

const Form = tw.form`mt-8 md:mt-10 text-sm flex max-w-lg mx-auto md:mx-0`
const SubmitButtonOne = tw(PrimaryButtonBase)`inline-block mr-0 lg:mr-4 rounded-full hover:bg-second-500`
const SubmitButtonTwo = tw(PrimaryButtonBase)`inline-block mt-4 lg:mt-0 rounded-full bg-black hover:bg-second-500`

export default ({
    heading = <>Looking For <span tw="text-second-500">Mobile</span> & <span tw="text-second-500">Web Application Development</span> Services</>,
    description = "Experience the expertise of our professional team in designing and developing exceptional mobile and web applications.",
    submitButtonOne = "Call Me",
    submitButtonTwo = "Schedule a Meeting",
    formAction = "#",
    formMethod = "get",
    textOnLeft = true,
}) => {
    // The textOnLeft boolean prop can be used to display either the text on left or right side of the image.

    return (
        <Container>
            <TwoColumn>
                <ImageColumn>
                    <Image imageSrc={HomeImg1} />
                </ImageColumn>
                <TextColumn textOnLeft={textOnLeft}>
                    <TextContent>
                        <Heading>{heading}</Heading>
                        <Description>{description}</Description>
                        <Form action={formAction} method={formMethod}></Form>
                        <SubmitButtonOne
                            onClick={(e) => {
                                localStorage.setItem('callType', 'callme');
                                document.getElementById("openSjainPopup").click();
                            }}
                            type="button"
                        >
                            {submitButtonOne}
                        </SubmitButtonOne>
                        <SubmitButtonTwo
                            type="button"
                            onClick={(e) => {
                                localStorage.setItem('callType', 'meeting');
                                document.getElementById("openSjainPopup").click();
                            }}
                        >
                            {submitButtonTwo}
                        </SubmitButtonTwo>

                    </TextContent>
                </TextColumn>
            </TwoColumn>
            <HomeShape />
        </Container>
    );
};
