import React from "react";
import tw from "twin.macro"; //eslint-disable-line
import { css } from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Header from "components/header/header.js";
import FormSection from "components/main/FormSection.js";
import ServicesSection from "components/main/ServicesSection.js";
import ProfessionalSection from "components/main/ProfessionalSection.js";
import StartedSection from "components/main/StartedSection.js";
import PrincipleSection from "components/main/PrincipleSection.js";
import TestimonialSection from "components/main/TestimonialSection.js";
import FaqSection from "components/main/FaqSection.js";
import ClientSection from "components/main/ClientSection.js";
import Footer from "components/footer/footer.js";

export default () => {

    return (
        <AnimationRevealPage>
            <Header roundedHeaderButton={true} />
            <FormSection />
            <ServicesSection />
            <ProfessionalSection />
            <StartedSection />
            <PrincipleSection />
            <TestimonialSection />
            <FaqSection />
            <ClientSection />
            <Footer />
        </AnimationRevealPage>
    );
}
