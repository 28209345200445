import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import HomeImg3 from "images/new-home-img3.svg";
import { ReactComponent as HomeShapeMain } from "../../images/home-shape-main.svg";

const Container = tw.div`relative`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto py-20 md:py-24 items-center`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(Column)`md:w-6/12 flex-shrink-0 relative`;
const TextColumn = styled(Column)(props => [
    tw`md:w-6/12 mt-16 md:mt-0`,
    props.textOnLeft ? tw`md:mr-12 lg:mr-12 md:order-first` : tw`md:ml-12 lg:ml-12 md:order-last`
]);

const HomeShape = styled(HomeShapeMain)`
    ${tw`pointer-events-none opacity-25 absolute right-0 bottom-0 h-56 w-56 transform translate-x-24 translate-y-16 -z-10 hidden md:block`}
`;

const Image = styled.img(props => []);

const TextContent = tw.div`text-center md:text-left`;

const Subheading = tw(SubheadingBase)`text-center md:text-left`;
const Heading = tw(
    SectionHeading
)`mt-4 font-black text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`;

const Steps = tw.ul`mt-12`;
const Step = tw.li`mt-8 flex flex-col md:flex-row items-center md:items-start`;
const StepNumber = tw.div`font-semibold text-4xl leading-none text-gray-400`;
const StepText = tw.div`mt-3 md:mt-0 md:ml-6`;
const StepHeading = tw.h6`leading-none text-xl font-semibold`;
const StepDescription = tw.p`mt-3 max-w-sm leading-loose text-sm text-gray-600 font-medium`;

export default ({
    subheading = "Steps",
    heading = (
        <>
            Easy to <span tw="text-second-500">Get Started</span>
        </>
    ),
    imageSrc = HomeImg3,
    textOnLeft = false,
    steps = null,
}) => {
    // The textOnLeft boolean prop can be used to display either the text on left or right side of the image.

    const defaultSteps = [
        {
            heading: "Enquiry Submission",
            description: "Submit the form and we will schedule a call to discuss your requirement in details."
        },
        {
            heading: "Financial & Technical Proposal",
            description: "Based on the discussion, we will prepare the Financial and Technical Proposal and share with you for the further processing and Approval."
        },
        {
            heading: "Development",
            description: "Once the Proposal is approved we will proceed with the Development Process and will complete the Project as per Project Plan."
        }
    ];

    if (!steps) steps = defaultSteps;

    return (
        <Container>
            <TwoColumn>
                <ImageColumn>
                    <Image src={imageSrc} />
                </ImageColumn>
                <TextColumn textOnLeft={textOnLeft}>
                    <TextContent>
                        <Subheading>{subheading}</Subheading>
                        <Heading>{heading}</Heading>
                        <Steps>
                            {steps.map((step, index) => (
                                <Step key={index}>
                                    <StepNumber>{(index + 1).toString().padStart(2, '0')}</StepNumber>
                                    <StepText>
                                        <StepHeading>{step.heading}</StepHeading>
                                        <StepDescription>{step.description}</StepDescription>
                                    </StepText>
                                </Step>
                            ))}
                        </Steps>
                    </TextContent>
                </TextColumn>
            </TwoColumn>
            <HomeShape />
        </Container>
    );
};
