import React, { useState } from "react";
import Slider from "react-slick";
import styled from "styled-components";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading as HeadingTitle } from "../misc/Headings.js";
import { Subheading as SubheadingBase } from "components/misc/Headings.js";
import { ReactComponent as ArrowLeftIcon } from "../../images/arrow-left-2-icon.svg";
import { ReactComponent as ArrowRightIcon } from "../../images/arrow-right-2-icon.svg";
import { ReactComponent as HomeShapeMain } from "../../images/home-shape-main.svg";

import "slick-carousel/slick/slick.css";

const Container = tw.div`relative`;
const Content = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;
const HeadingInfoContainer = tw.div`flex flex-col items-center`;
const Subheading = tw(SubheadingBase)`mb-4 text-center`;
const HeadingDescription = tw.p`mt-4 font-medium text-gray-600 text-center max-w-sm`;

const TestimonialSliderContainer = tw.div`mt-24`;
const TestimonialSlider = styled(Slider)``;
const Testimonial = tw.div`flex! flex-col items-center md:items-stretch md:flex-row md:justify-center outline-none`;
const ImageContainer = styled.div`
  ${tw`mx-4 rounded flex items-center max-w-xs md:max-w-none`}
  img {
    ${tw`rounded-none`}
  }
`;

const SliderControlButtonContainer = styled.div`
  ${tw`absolute top-0 h-full flex items-end md:items-center z-20`}
  button {
    ${tw`text-secondary-500 hover:text-primary-500 focus:outline-none transition duration-300 transform hover:scale-125 transform -translate-y-2/3 md:translate-y-0`}
    svg {
      ${tw`w-8`}
    }
  }
`;

const NextArrow = ({ currentSlide, slideCount, ...props }) => (
    <SliderControlButtonContainer tw="right-0 translate-x-4 md:translate-x-8">
        <button {...props}>
            <ArrowRightIcon />
        </button>
    </SliderControlButtonContainer>
);
const PreviousArrow = ({ currentSlide, slideCount, ...props }) => (
    <SliderControlButtonContainer tw="left-0 -translate-x-4 md:-translate-x-8">
        <button {...props}>
            <ArrowLeftIcon />
        </button>
    </SliderControlButtonContainer>
);

const HomeShape = styled(HomeShapeMain)`
    ${tw`pointer-events-none opacity-25 absolute right-0 bottom-0 h-56 w-56 transform translate-x-24 translate-y-16 -z-10 hidden md:block`}
`;

export default () => {
    // useState is used instead of useRef below because we want to re-render when sliderRef becomes available (not null)
    const [setSliderRef] = useState(null);
    const sliderSettings = {
        arrows: true,
        autoplay: false,
        slidesToShow: 5,
        responsive: [
            {
                breakpoint: 1280,
                settings: {
                    slidesToShow: 2,
                }
            },

            {
                breakpoint: 900,
                settings: {
                    slidesToShow: 1,
                }
            },
        ]
    };

    /*
     * You can modify the testimonials shown by modifying the array below
     * You can add or remove objects from the array as you need.
     */
    const testimonials = [
        { imageSrc: "https://sjain.io/landing/2022/img/clients/khaleejtimes.jpg" },
        { imageSrc: "https://sjain.io/landing/2022/img/clients/gulfnews.jpg" },
        { imageSrc: "https://sjain.io/landing/2022/img/clients/sedar.jpg" },
        { imageSrc: "https://sjain.io/landing/2022/img/clients/docsta.jpg" },
        { imageSrc: "https://sjain.io/landing/2022/img/clients/daralber.jpg" },
        { imageSrc: "https://sjain.io/landing/2022/img/clients/vachan.jpg" },
        { imageSrc: "https://sjain.io/landing/2022/img/clients/trippycar.jpg" },
        { imageSrc: "https://sjain.io/landing/2022/img/clients/nhmmi.jpg" },
        { imageSrc: "https://sjain.io/landing/2022/img/clients/obcl.jpg" },
        { imageSrc: "https://sjain.io/landing/2022/img/clients/bagadiya.jpg" },
        { imageSrc: "https://sjain.io/landing/2022/img/clients/legendcar.jpg" },
        { imageSrc: "https://sjain.io/landing/2022/img/clients/iflm.jpg" },
        { imageSrc: "https://sjain.io/landing/2022/img/clients/lefebvre.jpg" },
        { imageSrc: "https://sjain.io/landing/2022/img/clients/popcorn.jpg" }
    ];
    return (
        <Container>
            <Content>
                <HeadingInfoContainer>
                    <Subheading>Clients</Subheading>
                    <HeadingTitle>Our Awesome <span tw="text-second-500">Customers</span></HeadingTitle>
                    <HeadingDescription>A true partnership is a two-way street.</HeadingDescription>
                </HeadingInfoContainer>
                <TestimonialSliderContainer>
                    <TestimonialSlider ref={setSliderRef} {...sliderSettings} nextArrow={<NextArrow />} prevArrow={<PreviousArrow />}>
                        {testimonials.map((testimonial, index) => (
                            <Testimonial key={index}>
                                <ImageContainer>
                                    <img src={testimonial.imageSrc} alt={testimonial.customerName} />
                                </ImageContainer>
                            </Testimonial>
                        ))}
                    </TestimonialSlider>
                </TestimonialSliderContainer>
            </Content>
            <HomeShape />
        </Container>
    );
};
