import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { Container as ContainerBase } from "components/misc/Layouts.js"

const Container = tw(ContainerBase)`-mb-8`
const Content = tw.div`max-w-screen-xl mx-auto pt-5 pb-10 border-t-2 border-gray-200`;
const Row = tw.div`flex items-center justify-center flex-col px-8`
const CopyrightText = tw.p`text-center mt-1 font-medium tracking-wide text-sm text-gray-700`

const PopupButton = styled.button`
  ${tw`absolute bottom-0 md:top-0 left-0 focus:outline-none`}
  .arrowUp {
    ${tw`stroke-1 w-10 h-10`}
  }
`;

const today = new Date();

export default () => {
    return (
        <Container>
            <Content>
                <Row>
                    <PopupButton onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="arrowUp">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 6.75L12 3m0 0l3.75 3.75M12 3v18" />
                        </svg>
                    </PopupButton>
                    <CopyrightText>
                        Sjain Ventures &copy; Copyright {today.getFullYear()}. All Rights Reserved.
                    </CopyrightText>
                </Row>
            </Content>
        </Container>
    );
};
