import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
//eslint-disable-next-line
import { css } from "styled-components/macro";
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { SectionDescription } from "components/misc/Typography.js";

import defaultCardImage from "images/shield-icon-new.svg";

import { ReactComponent as HomeShapeMain } from "../../images/home-shape-main.svg";

import ShieldIconImage from "images/shield-icon-new.svg";
import SupportIconImage from "images/support-icon-new.svg";
import CustomizeIconImage from "images/customize-icon-new.svg";
import ReliableIconImage from "images/reliable-icon-new.svg";
import FastIconImage from "images/fast-icon-new.svg";
import SimpleIconImage from "images/simple-icon-new.svg";

const Container = tw.div`relative`;

const ThreeColumnContainer = styled.div`
    ${tw`flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-between max-w-screen-xl mx-auto py-20 md:py-24`}
`;

const HomeShape = styled(HomeShapeMain)`
  ${tw`pointer-events-none opacity-25 absolute right-0 bottom-0 h-56 w-56 transform translate-x-24 translate-y-16 -z-10 hidden md:block`}
`;

const Subheading = tw(SubheadingBase)`mb-4 w-full text-center`;
const Heading = tw(SectionHeading)`w-full`;
const Description = tw(SectionDescription)`w-full max-w-full! text-center`;

const VerticalSpacer = tw.div`mt-10 w-full`

const Column = styled.div`
  ${tw`md:w-1/2 lg:w-1/3`}
`;

const Card = styled.div`
  ${tw`flex flex-col sm:flex-row items-center sm:items-start text-center sm:text-left h-full py-8 pr-4`}
  .imageContainer {
    ${tw`border text-center rounded-full p-5 flex-shrink-0`}
    img {
      ${tw`w-6 h-6`}
    }
  }

  .textContainer {
    ${tw`sm:ml-4 mt-4 sm:mt-2`}
  }

  .title {
    ${tw`mt-4 tracking-wide font-bold text-2xl leading-none`}
  }

  .description {
    ${tw`mt-1 sm:mt-4 font-medium text-secondary-100 leading-loose`}
  }
`;

export default ({
    cards = null,
    heading = (
        <>
            We have <span tw="text-second-500">Mobile</span> & <span tw="text-second-500">Web Application Development</span> Services
        </>
    ),
    subheading = "Services",
    description = "Transforming ideas into seamless mobile and web experiences."
}) => {
    /*
     * This componets has an array of object denoting the cards defined below. Each object in the cards array can have the key (Change it according to your need, you can also add more objects to have more cards in this feature component) or you can directly pass this using the cards prop:
     *  1) imageSrc - the image shown at the top of the card
     *  2) title - the title of the card
     *  3) description - the description of the card
     *  If a key for a particular card is not provided, a default value is used
     */

    const defaultCards = [
        {
            imageSrc: ShieldIconImage,
            title: "Secure",
            description: "Ensuring security at every stage, we develop robust web and app solutions to protect your data and user privacy."
        },
        {
            imageSrc: SupportIconImage,
            title: "Support",
            description: "We offer dedicated support for web and app development, providing timely assistance, troubleshooting, and continuous improvement to keep your digital solutions running smoothly."
        },
        {
            imageSrc: CustomizeIconImage,
            title: "Customizable",
            description: "Experience the power of customisable web and app development as we create bespoke solutions that align with your specific needs, preferences, and future scalability."
        },
        {
            imageSrc: ReliableIconImage,
            title: "Reliable",
            description: "Trust our experienced team for reliable web and app development services that consistently deliver high-quality, efficient, and error-free solutions."
        },
        {
            imageSrc: FastIconImage,
            title: "Fast",
            description: "Our agile development approach enables fast web and app development, allowing you to launch your digital solutions in record time."
        },
        {
            imageSrc: SimpleIconImage,
            title: "Easy",
            description: "With a focus on usability, our web and app development services prioritize intuitive interfaces and seamless interactions, allowing users to effortlessly navigate and accomplish their goals."
        }
    ];

    if (!cards) cards = defaultCards;

    return (
        <Container>
            <ThreeColumnContainer>
                {subheading && <Subheading>{subheading}</Subheading>}
                <Heading>{heading}</Heading>
                {description && <Description>{description}</Description>}
                <VerticalSpacer />
                {cards.map((card, i) => (
                    <Column key={i}>
                        <Card>
                            <span className="imageContainer">
                                <img src={card.imageSrc || defaultCardImage} alt="" />
                            </span>
                            <span className="textContainer">
                                <span className="title">{card.title || "Fully Secure"}</span>
                                <p className="description">
                                    {card.description || "Lorem ipsum donor amet siti ceali ut enim ad minim veniam, quis nostrud."}
                                </p>
                            </span>
                        </Card>
                    </Column>
                ))}
            </ThreeColumnContainer>
            <HomeShape />
        </Container>
    );
};
