import React, { useState } from "react";
import { motion } from "framer-motion";
import styled from "styled-components";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { SectionDescription } from "components/misc/Typography.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts.js";
import { ReactComponent as ChevronDownIcon } from "feather-icons/dist/icons/chevron-down.svg";
import { ReactComponent as HomeShapeFaq } from "images/home-shape-faq.svg";

const Subheading = tw(SubheadingBase)`mb-4 text-center`;
const Heading = tw(SectionHeading)`w-full`;
const Description = tw(SectionDescription)`w-full text-center`;

const Column = tw.div`flex flex-col items-center`;
const HeaderContent = tw.div``;

const FAQSContainer = tw.dl`mt-12 max-w-4xl relative`;
const FAQ = tw.div`cursor-pointer select-none mt-5 px-8 sm:px-10 py-5 sm:py-4 rounded-lg text-gray-800 hover:text-gray-900 bg-gray-200 hover:bg-gray-300 transition duration-300`;
const Question = tw.dt`flex justify-between items-center`;
const QuestionText = tw.span`text-lg lg:text-xl font-semibold`;
const QuestionToggleIcon = motion(styled.span`
  ${tw`ml-2 transition duration-300`}
  svg {
    ${tw`w-6 h-6`}
  }
`);
const Answer = motion(tw.dd`pointer-events-none text-sm sm:text-base leading-relaxed`);

const HomeShape = styled(HomeShapeFaq)`
  ${tw`pointer-events-none -z-20 absolute left-0 bottom-0 h-2/3 w-2/3 opacity-100 transform -translate-x-[45%] -translate-y-24 hidden md:block`}
`;



export default ({
    subheading = "FAQS",
    heading = (
        <>
            You have <span tw="text-second-500">Questions?</span>
        </>
    ),
    description = "And we have got answers to all of them.",
    faqs = [
        {
            question: "What is web development?",
            answer: "Web development refers to the process of creating websites or web applications. It involves writing code in programming languages such as HTML, CSS, and JavaScript to build the structure, design, and functionality of a website."
        },
        {
            question: "What is app development?",
            answer: "App development refers to the process of creating mobile applications for devices like smartphones and tablets. It involves writing code using programming languages like Java or Swift to build the functionality and user interface of the app."
        },
        {
            question: "What is the difference between web development and app development?",
            answer: "Web development focuses on creating websites that can be accessed through web browsers, while app development is specifically for building applications for mobile devices that can be downloaded and installed."
        },
        {
            question: "What programming languages are commonly used in web development?",
            answer: "Some commonly used programming languages in web development include HTML, CSS, JavaScript, Python, PHP, and Ruby."
        },
        {
            question: "What programming languages are commonly used in app development?",
            answer: "App development for iOS typically involves programming in Swift or Objective-C, while app development for Android uses Java or Kotlin."
        },
        {
            question: "What is responsive web design?",
            answer: "Responsive web design is an approach that aims to create websites that provide an optimal viewing experience across different devices and screen sizes. The layout and design of the website adapt and adjust dynamically based on the user's device."
        },
        {
            question: "What are front-end and back-end development?",
            answer: "Front-end development refers to the client-side development that focuses on the user interface and user experience of a website or application. Back-end development, on the other hand, involves server-side programming and handles the behind-the-scenes functionality and data management."
        },
        {
            question: "What is a content management system (CMS)?",
            answer: "A content management system is a software application or platform that allows users to create, manage, and modify digital content on websites without requiring extensive technical knowledge. Examples of popular CMS platforms include WordPress, Joomla, and Drupal."
        },
        {
            question: "What is the difference between a native app and a web app?",
            answer: "A native app is specifically developed for a particular platform (e.g., iOS or Android) and is installed on the user's device, whereas a web app is accessed through a web browser and does not require installation."
        },
        {
            question: "What is the importance of testing in web and app development?",
            answer: "Testing is crucial in web and app development to ensure that the software functions as intended, is free of bugs or errors, and provides a good user experience. It helps identify and fix issues before the website or app is released to the public."
        }
    ]
}) => {
    const [activeQuestionIndex, setActiveQuestionIndex] = useState(null);

    const toggleQuestion = questionIndex => {
        if (activeQuestionIndex === questionIndex) setActiveQuestionIndex(null);
        else setActiveQuestionIndex(questionIndex);
    };

    return (
        <Container>
            <ContentWithPaddingXl>
                <Column>
                    <HeaderContent>
                        {subheading && <Subheading>{subheading}</Subheading>}
                        <Heading>{heading}</Heading>
                        {description && <Description>{description}</Description>}
                    </HeaderContent>
                    <FAQSContainer>
                        {faqs.map((faq, index) => (
                            <FAQ
                                key={index}
                                onClick={() => {
                                    toggleQuestion(index);
                                }}
                                className="group"
                            >
                                <Question>
                                    <QuestionText>{faq.question}</QuestionText>
                                    <QuestionToggleIcon
                                        variants={{
                                            collapsed: { rotate: 0 },
                                            open: { rotate: -180 }
                                        }}
                                        initial="collapsed"
                                        animate={activeQuestionIndex === index ? "open" : "collapsed"}
                                        transition={{ duration: 0.02, ease: [0.04, 0.62, 0.23, 0.98] }}
                                    >
                                        <ChevronDownIcon />
                                    </QuestionToggleIcon>
                                </Question>
                                <Answer
                                    variants={{
                                        open: { opacity: 1, height: "auto", marginTop: "16px" },
                                        collapsed: { opacity: 0, height: 0, marginTop: "0px" }
                                    }}
                                    initial="collapsed"
                                    animate={activeQuestionIndex === index ? "open" : "collapsed"}
                                    transition={{ duration: 0.3, ease: [0.04, 0.62, 0.23, 0.98] }}
                                >
                                    {faq.answer}
                                </Answer>
                            </FAQ>
                        ))}
                    </FAQSContainer>
                </Column>
            </ContentWithPaddingXl>
            <HomeShape />
        </Container>
    );
};
